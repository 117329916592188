<template>
  <div>
    <el-divider></el-divider>
    <el-row type="flex" justify="space-around">
      <van-button round icon="edit" type="info" @click="outHouseByHand">手动出库</van-button>
      <van-button round icon="scan" type="primary" @click="scanOut">扫码出库</van-button>
    </el-row>
    <el-divider>我的出库单</el-divider>
    <van-list v-model="listLoading" :finished="listFinished" @load="getList" finished-text="没有更多了">
      <van-cell-group v-for="(item,index) in listData" :key="index" :title="item.deviceName">
        <template #title v-if="index==0&&showNew">
          <el-row type="flex" justify="space-between">
            <span>{{item.deviceName}}</span>
            <van-tag type="danger">新</van-tag>
          </el-row>
        </template>
        <van-cell title="序列号" :value="item.serialNumber"></van-cell>
        <van-cell title="贴牌公司名" :value="item.companyName"></van-cell>
        <van-cell title="型号" :value="item.specification"></van-cell>
        <van-cell title="操作" :value="item.operate"></van-cell>
        <van-cell title="操作时间" :value="item.insertTime"></van-cell>
        <van-cell title="操作人" :value="item.insertUserName"></van-cell>
        <van-cell title="备注" :value="item.remark"></van-cell>
      </van-cell-group>
    </van-list>
    <van-dialog v-model="inDialogVisible" show-cancel-button @confirm="addModel">
      <van-field label="序列号" placeholder="请输入序列号" v-model="serialNumber"></van-field>
      <van-field label="备注" placeholder="请输入序备注" v-model="remark"></van-field>
    </van-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";
Vue.use(Vant);
export default {
  data() {
    return {
      inDialogVisible: false,
      serialNumber: "",
      remark: "",
      listLoading: false,
      listFinished: false,
      listData: [],
      currentPage: 1,
      wx: null,
      showNew: false,
    };
  },
  methods: {
    outHouseByHand() {
      let that = this;
      that.serialNumber = "";
      that.remark = "";
      that.inDialogVisible = true;
    },
    addModel() {
      let that = this;
      that.axios
        .post("ProductWarehouse/AddModel", {
          serialNumber: that.serialNumber,
          remark: that.remark,
          operate: "出库",
        })
        .then(function (response) {
          if (response.data.code == 101) {
            that.listData.unshift(response.data.data);
            that.showNew = true;
          } else {
            that.showNew = false;
            that.$toast(response.data.msg);
          }
        });
    },
    getList() {
      let that = this;
      that.axios
        .post("ProductWarehouse/GetList", {
          onlyText: "出库",
          currentPage: that.currentPage,
        })
        .then(function (response) {
          response.data.data.data.forEach((element) => {
            that.listData.push(element);
          });
          that.listLoading = false;
          if (that.listData.length >= response.data.data.total) {
            that.listFinished = true;
          } else {
            that.currentPage++;
          }
        });
    },
    scanOut() {
      let that = this;
      that.wx.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode"],
        success: function (res) {
          that.serialNumber = res.resultStr.substr(25);
          that.remark = "扫码出库";
          that.addModel();
        },
      });
    },
  },
  mounted() {
    let that = this;
    that.axios
      .post("OfficialAccount/GetWXScanAPI", { onlyText: window.location.href })
      .then(function (response) {
        that.wx = require("weixin-js-sdk");
        that.wx.config({
          debug: false,
          appId: response.data.data.appId,
          timestamp: response.data.data.timestamp,
          nonceStr: response.data.data.nonceStr,
          signature: response.data.data.signature,
          jsApiList: ["scanQRCode"],
        });
        that.wx.ready(function () {});
        that.wx.error(function (err) {
          alert("error" + JSON.stringify(err));
        });
      });
  },
};
</script>

<style>
</style>